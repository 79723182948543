class Route {
    constructor(public readonly url: string) { }
}

class Heading {
    constructor(private readonly page: Route, private readonly id: string) { }

    url(): string {
        return this.page.url + "#" + this.id;
    }
}

class FaqPage extends Route {
    public readonly are_treasures_real: Heading;
    public readonly who_funds_treasures: Heading;
    public readonly how_do_tokens_work: Heading;
    public readonly how_do_geocoins_work: Heading;
    public readonly how_winnings_are_determined: Heading;
    public readonly leaving_tokens_behind: Heading;

    constructor() {
        super("/faq/");
        this.are_treasures_real = new Heading(this, 'are-treasures-real');
        this.who_funds_treasures = new Heading(this, 'who-funds-treasures');
        this.how_do_tokens_work = new Heading(this, 'how-do-tokens-work');
        this.how_do_geocoins_work = new Heading(this, 'how-do-geocoins-work');
        this.how_winnings_are_determined = new Heading(this, 'how-winnings-are-determined');
        this.leaving_tokens_behind = new Heading(this, 'leaving-tokens-behind');
    }
}

class TermsOfUsePage extends Route {
    public readonly terms_of_use_heading: Heading;
    public readonly privacy_policy_hading: Heading;

    constructor() {
        super("/terms-of-use/");
        this.terms_of_use_heading = new Heading(this, 'terms-of-use');
        this.privacy_policy_hading = new Heading(this, 'privacy-policy');
    }
}

class Routing {
    static readonly ABOUT_PAGE = new Route("/");
    static readonly EXPLORE_PAGE = new Route("/explore/");
    static readonly WIN_PAGE = new Route("/win/");
    static readonly FAQ_PAGE = new FaqPage();

    static readonly TERMS_OF_USE_PAGE = new TermsOfUsePage();
    static readonly CONTACT_US_PAGE = new Route("/contact-us/");

    static readonly CREATE_ACCOUNT_PAGE = new Route("/account/create");
    static readonly CREATE_ACCOUNT_WITH_EMAIL_PAGE_NOTUSED = new Route("/account/create/email");
    static readonly LOGIN_PAGE = new Route("/account/login");
    static readonly LOGOUT_PAGE = new Route('/account/logout');

    static readonly ACCOUNT_INFO_PAGE = new Route("/account/info");
    static readonly MY_TOKENS_PAGE = new Route("/account/my-tokens");
}


export default Routing;
