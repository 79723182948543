import { AuthCookieService } from "../../API";
import { Redirect } from "../../Redirect";
import Routing from "../../Routing";
import { useAsyncEfect } from "../../utils/ReactUtils";

export function LogoutPage() {
    useAsyncEfect(async () => {
        await AuthCookieService().logoutAccount();
        Redirect(Routing.LOGIN_PAGE.url);
    }, []);
    return (<></>);
}