import { OAuthButtonGoogle, OAuthButtonFacebook, OAuthButtonApple } from '../../components/OAuthButtons';
import {
    CreateAccountContentCard,
    CreateAccountButtonOnClick,
    CreateAccountButtonSubmit,
    CreateAccountDontHaveAnAccountSignup,
    CreateAccountDivider,
} from './CreateAccountWidgets';
import Routing from '../../Routing';

import './CreateAccountPage.css';
import React, { useState } from 'react';
import { PublicService, AuthCookieService } from '../../API';
import { impossible } from '../../utils/impossible';
import { RedirectAfterAuth } from '../../Redirect';

function LoginPage() {
    const [remoteError, setRemoteError] = useState<string | null>(null);
    const [canSubmit, setCanSubmit] = useState(true);

    const [formEmail, setFormEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setCanSubmit(false);
        var shouldReenable = true;
        try {
            const result = await PublicService().loginAccount({
                email: formEmail,
                password: formPassword,
            });
            var jwt = null;
            switch (result.result.kind) {
                case "error":
                    setRemoteError(result.result.error);
                    return;
                case "jwt":
                    jwt = result.result.jwt;
                    shouldReenable = false;
                    break;
                default:
                    impossible(result.result);
            }
            await AuthCookieService().authCookie({
                jwt: jwt,
            });
            setRemoteError(null);
            RedirectAfterAuth();
        } finally {
            if (shouldReenable) {
                setCanSubmit(true);
            }
        }
    }

    return (<form method="post" onSubmit={handleSubmit}>
        <CreateAccountContentCard title="Log in">
            <>
                <label htmlFor="create-account-email" className="form-label">Email</label>
                <input
                    type="email"
                    id="create-account-email"
                    className="form-control"
                    value={formEmail}
                    onChange={(e) => setFormEmail(e.currentTarget.value)} />
            </>
            <>
                <label htmlFor="create-account-password" className="form-label">Password</label>
                <input
                    type="password"
                    id="create-account-password"
                    className="form-control"
                    value={formPassword}
                    onChange={(e) => setFormPassword(e.currentTarget.value)} />
            </>
            {remoteError === null ? <></> :
                <small className="form-text text-danger">
                    {remoteError}
                </small>}
            <CreateAccountButtonSubmit text="Log in" disabled={!canSubmit} />
            {/* <CreateAccountDivider />
            <OAuthButtonGoogle prompt="Sign in with Google" />
            <OAuthButtonFacebook prompt="Sign in with Facebook" />
            <OAuthButtonApple prompt="Sign in with Apple" /> */}
            <CreateAccountDontHaveAnAccountSignup />
        </CreateAccountContentCard>
    </form>);
}

export default LoginPage;