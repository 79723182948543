import { PropsWithChildren, useState } from 'react';
import './App.css';
import { Navbar, NavbarPage } from './components/Navbar';
import { useAsyncEfect } from './utils/ReactUtils';
import { PublicService } from './API';
import { AccountInfoResult } from '../../common/ts/wwwapi';

interface AppProps extends PropsWithChildren {
  activeNavbarPage?: NavbarPage;
  fillHeight?: boolean;
}

function App(props: AppProps) {
  const [loggedInUser, setLoggedInUser] = useState<AccountInfoResult | null | "downloading">("downloading");

  useAsyncEfect(async () => {
    const u = await PublicService().maybeAccountInfo();
    setLoggedInUser(u);
  }, []);

  return (
    <main className={`${props.fillHeight ? "App-fill-content" : ""}`}>
      <Navbar
        activePage={props.activeNavbarPage ?? null}
        accountInfo={loggedInUser} />
      <div
        className="App">
        {props.children}
      </div>
    </main>
  );
}

export default App;
