import React, { PropsWithChildren } from "react";

import Routing from '../../Routing';

function CreateAccountButtonURL(
    props: {
        text: string,
        href: string
    }
) {
    return (<div className="d-grid">
        {/* <button type="button" className="btn btn-primary">{props.text}</button> */}
        <a type="button" className="btn btn-primary" href={Routing.CREATE_ACCOUNT_WITH_EMAIL_PAGE_NOTUSED.url}>{props.text}</a>
    </div>);
}

function CreateAccountButtonOnClick(
    props: {
        text: string,
        onClick: React.MouseEventHandler<HTMLButtonElement>
    }
) {
    return (<div className="d-grid">
        <button type="button" className="btn btn-primary" onClick={props.onClick}>{props.text}</button>
    </div>);
}

function CreateAccountButtonSubmit(
    props: {
        text: string;
        disabled: boolean;
    }
) {
    return (<div className="d-grid">
        <button type="submit" className="btn btn-primary" disabled={props.disabled}>{props.text}</button>
    </div>);
}

function CreateAccountDivider() {
    return (<div className="row">
        <div className="col"><hr /></div>
        <div className="col-auto">or</div>
        <div className="col"><hr /></div>
    </div>);
}

function CreateAccountText(props: {
    content: React.ReactNode | string,
}) {
    return (<div className="row text-center">
        <span className="text-secondary">{props.content}</span>
    </div>);
}

function CreateAccountHaveAnAccountLogin() {
    const x = (<>
        Have an account? <a href={Routing.LOGIN_PAGE.url}>Login</a>
    </>);
    return (<CreateAccountText content={x} />);
}

function CreateAccountDontHaveAnAccountSignup() {
    const x = (<>
        Don't have an account? <a href={Routing.CREATE_ACCOUNT_PAGE.url}>Sign Up</a>
    </>);
    return (<CreateAccountText content={x} />);
}

function CreateAccountContentGrid(props: PropsWithChildren) {
    const cs = React.Children.toArray(props.children);
    const wrapped = cs.map((c) => (
        <div className="col-12">
            {c}
        </div>
    ));

    return (<div className="card-body text-start pt-4">
        <div className="row gy-3">
            {wrapped}
        </div>
    </div>);
}

function CreateAccountContentCard(
    props: { title: string; } & PropsWithChildren,
) {
    return (<div className="container py-4 my-auto text-center CreateAccountPage-container">
        <div className="card">

            <div className="card-header CreateAccountPage-header">
                <span>{props.title}</span>
            </div>
            <CreateAccountContentGrid>
                {props.children}
            </CreateAccountContentGrid>
        </div>
    </div>);
}

export {
    CreateAccountButtonURL,
    CreateAccountButtonOnClick,
    CreateAccountButtonSubmit,
    CreateAccountDivider,
    CreateAccountText,

    CreateAccountHaveAnAccountLogin,
    CreateAccountDontHaveAnAccountSignup,

    CreateAccountContentCard,
};