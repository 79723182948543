import { rpcClient, RpcError } from "typed-rpc";

import {
    PublicAPI,
    AuthCookieServiceAPI,
    AuthenticatedAPI,

    Unauthorized,
    Authorized,
} from "../../common/ts/wwwapi";

import { impossible } from "./utils/impossible";
import { AuthThenRedirect } from "./Redirect";
import Config from "./Config";

function urlForPath(path: string): string {
    const slash = path.startsWith('/') ? "" : '/';
    return `${Config.protocol}://${Config.host}${slash}${path}`;
}


export class MaybeAuthorized<T> {
    constructor(public result: Unauthorized | Authorized<T>) { }

    authorize(): T | null {
        switch (this.result.kind) {
            case "unauthorized":
                return null;
            case "authorized":
                return this.result.result;
            default:
                impossible(this.result);
        }
    }
}

export type MaybeAuthorizeReturnType<T> = T extends (...args: any[]) => Promise<infer R>
    ? (...args: Parameters<T>) => Promise<MaybeAuthorized<R>>
    : never;

var PUBLIC_SERVICE_SINGLETON: null | ReturnType<typeof rpcClient<PublicAPI>> = null;

export function PublicService(): ReturnType<typeof rpcClient<PublicAPI>> {
    if (PUBLIC_SERVICE_SINGLETON !== null) {
        return PUBLIC_SERVICE_SINGLETON;
    }
    PUBLIC_SERVICE_SINGLETON = rpcClient<PublicAPI>(urlForPath("/api/public"));
    return PUBLIC_SERVICE_SINGLETON;
}

var AUTH_COOKIE_SERVICE_SINGLETON: null | ReturnType<typeof rpcClient<AuthCookieServiceAPI>> = null;

export function AuthCookieService(): ReturnType<typeof rpcClient<AuthCookieServiceAPI>> {
    if (AUTH_COOKIE_SERVICE_SINGLETON !== null) {
        return AUTH_COOKIE_SERVICE_SINGLETON;
    }
    AUTH_COOKIE_SERVICE_SINGLETON = rpcClient<AuthCookieServiceAPI>(urlForPath("/api/auth-cookie"));
    return AUTH_COOKIE_SERVICE_SINGLETON;
}

export type AuthenticatedAPIWithReturnType = {
    [K in keyof AuthenticatedAPI]: MaybeAuthorizeReturnType<AuthenticatedAPI[K]>;
}

var AUTHENTICATED_SERVICE_SINGLETON: null | ReturnType<typeof rpcClient<AuthenticatedAPIWithReturnType>> = null;

export function AuthenticatedService(): ReturnType<typeof rpcClient<AuthenticatedAPIWithReturnType>> {
    if (AUTHENTICATED_SERVICE_SINGLETON !== null) {
        return AUTHENTICATED_SERVICE_SINGLETON;
    }
    const client = rpcClient<AuthenticatedAPIWithReturnType>({
        url: urlForPath("/api/authenticated"),
        credentials: "include",
    });
    AUTHENTICATED_SERVICE_SINGLETON = new Proxy(client, {
        get(target, prop, receiver) {
            const f = Reflect.get(target, prop, receiver);
            return async (...args: any[]): Promise<MaybeAuthorized<any>> => {
                try {
                    const result = await f(...args);
                    return new MaybeAuthorized({
                        kind: "authorized",
                        result: result,
                    });
                } catch (e) {
                    if (e instanceof RpcError && e.code === 401) {
                        AuthThenRedirect();
                        return new MaybeAuthorized({
                            kind: "unauthorized",
                        });
                    }
                    throw e;
                }
            }
        }
    });
    return AUTHENTICATED_SERVICE_SINGLETON;
}