function assertString(str: unknown): string {
    if (str === undefined || str === null || typeof str !== 'string') {
        throw new Error(`assertString: failure: got ${str}`);
    }
    return str;
}

const Config = {
    'protocol': assertString(process.env.REACT_APP_GBH_PROTOCOL),
    'host': assertString(process.env.REACT_APP_GBH_HOSTNAME),
};

export default Config;