import './WinPage.css';

import React, { useState, useRef } from 'react';

import { FocusRef, SetFocus, useFocus } from '../utils/UseFocus';

import tokenPicture from './AboutPage/token.jpeg';

enum ThreeDigitsInputStyle {
    EMPTY,
    MAYBE,
    SUCCESS,
    FAILURE,
}

function mapThreeDigitsInputStyle<T>(f: {
    onEmpty: () => T,
    onMaybe: () => T,
    onSuccess: () => T,
    onFailure: () => T,
}): (s: ThreeDigitsInputStyle) => T {
    return (s: ThreeDigitsInputStyle): T => {
        switch (s) {
            case ThreeDigitsInputStyle.EMPTY:
                return f.onEmpty();
            case ThreeDigitsInputStyle.MAYBE:
                return f.onMaybe();
            case ThreeDigitsInputStyle.SUCCESS:
                return f.onSuccess();
            case ThreeDigitsInputStyle.FAILURE:
                return f.onFailure();
            default:
                const never: never = s;
                throw new Error(`Unsound ${never}`);
        }
    };
}

enum FullCodeState {
    INCOMPLETE,
    CORRECT,
    WRONG,
}

function mapFullCodeState<T>(f: {
    onIncomplete: () => T,
    onCorrect: () => T,
    onWrong: () => T,
}): (s: FullCodeState) => T {
    return (s: FullCodeState): T => {
        switch (s) {
            case FullCodeState.INCOMPLETE:
                return f.onIncomplete();
            case FullCodeState.CORRECT:
                return f.onCorrect();
            case FullCodeState.WRONG:
                return f.onWrong();
            default:
                const never: never = s;
                throw new Error(`Unsound ${never}`);
        }
    };
}

interface ThreeDigitsInputProps {
    fullCodeState: FullCodeState;
    hasThreeDigits: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    focusRef: FocusRef,
    onNavigateToNextRequest: () => void,
    onNavigateToPreviousRequest: () => void,
}

class ThreeDigitsInputPropsF {
    static getStyle(props: ThreeDigitsInputProps): ThreeDigitsInputStyle {
        return mapFullCodeState({
            onCorrect: () => ThreeDigitsInputStyle.SUCCESS,
            onWrong: () => ThreeDigitsInputStyle.FAILURE,
            onIncomplete: () => props.hasThreeDigits ? ThreeDigitsInputStyle.MAYBE : ThreeDigitsInputStyle.EMPTY,
        })(props.fullCodeState);
    }
}

function ThreeDigitsInput(props: ThreeDigitsInputProps) {
    const inputClass = mapThreeDigitsInputStyle({
        onEmpty: () => "",
        onMaybe: () => "WinPage-input-maybe",
        onSuccess: () => "WinPage-input-success",
        onFailure: () => "WinPage-input-failure",
    })(ThreeDigitsInputPropsF.getStyle(props));

    return (
        <div className="col-auto">
            <input
                className={`form-control form-control-sm-lg text-center ${inputClass}`}
                type="text"
                size={3}
                maxLength={3}
                placeholder="123"
                pattern="[0-9]*"
                onChange={props.onChange}
                ref={props.focusRef}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Backspace" && !props.focusRef.current?.value) {
                        props.onNavigateToPreviousRequest();
                    }
                }}
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (props.focusRef.current?.value.length === 3) {
                        props.onNavigateToNextRequest();
                    }
                }}
            />
        </div>
    );
}

function ThreeDigitsSeparator() {
    return (
        <div className="col-auto px-2">
            <label
                className="col-form-label form-control-sm-lg">
                &#x25CF;
            </label>
        </div>
    );
}

interface ThreeDigitsInputState {
    value: string;
}

class ThreeDigitsInputStateF {
    static default(): ThreeDigitsInputState {
        return {
            value: "",
        };
    }

    static hasThreeDigits(x: ThreeDigitsInputState): boolean {
        return x.value.length == 3;
    }
}

interface AllDigitInputsProps {
    s1: ThreeDigitsInputProps,
    s2: ThreeDigitsInputProps,
    s3: ThreeDigitsInputProps,
    s4: ThreeDigitsInputProps,
}

interface AllDigitInputsState {
    s1: ThreeDigitsInputState;
    s2: ThreeDigitsInputState;
    s3: ThreeDigitsInputState;
    s4: ThreeDigitsInputState;

    fullCodeState: FullCodeState;
}

type AllDigitInputsStateGetter = (s: AllDigitInputsState) => ThreeDigitsInputState;
type AllDigitInputsStateSetter = (s: AllDigitInputsState, t: ThreeDigitsInputState) => AllDigitInputsState;

class AllDigitInputsStateF {
    static default(): AllDigitInputsState {
        return {
            s1: ThreeDigitsInputStateF.default(),
            s2: ThreeDigitsInputStateF.default(),
            s3: ThreeDigitsInputStateF.default(),
            s4: ThreeDigitsInputStateF.default(),
            fullCodeState: FullCodeState.INCOMPLETE,
        };
    }

    static all(s: AllDigitInputsState): ThreeDigitsInputState[] {
        return [s.s1, s.s2, s.s3, s.s4];
    }

    static withS1(s: AllDigitInputsState, ss: ThreeDigitsInputState): AllDigitInputsState {
        return { ...s, s1: ss }
    }
    static withS2(s: AllDigitInputsState, ss: ThreeDigitsInputState): AllDigitInputsState {
        return { ...s, s2: ss }
    }
    static withS3(s: AllDigitInputsState, ss: ThreeDigitsInputState): AllDigitInputsState {
        return { ...s, s3: ss }
    }
    static withS4(s: AllDigitInputsState, ss: ThreeDigitsInputState): AllDigitInputsState {
        return { ...s, s4: ss }
    }

    static updateText(
        oldState: AllDigitInputsState,
        setInputState: AllDigitInputsStateSetter,
        t: string
    ): AllDigitInputsState {
        const s = setInputState(oldState, { value: t });

        var fullCodeState = FullCodeState.INCOMPLETE;
        const isFull = this.all(s).every((ss) => ThreeDigitsInputStateF.hasThreeDigits(ss));
        if (isFull) {
            const isCorrect = Math.random() < 0.5;
            console.log("IS CORRECT: " + isCorrect.toString());
            fullCodeState = isCorrect ? FullCodeState.CORRECT : FullCodeState.WRONG;
        }
        return {
            ...s,
            fullCodeState: fullCodeState,
        };
    }

    static getPropsFor(
        s: AllDigitInputsState,
        getInputState: AllDigitInputsStateGetter,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        focusRef: FocusRef,
        setFocusPrev: SetFocus | null,
        setFocusNext: SetFocus | null,
    ): ThreeDigitsInputProps {
        const ss = getInputState(s);
        return {
            hasThreeDigits: ThreeDigitsInputStateF.hasThreeDigits(ss),
            fullCodeState: s.fullCodeState,
            onChange: onChange,
            focusRef: focusRef,
            onNavigateToPreviousRequest:
                () => setFocusPrev ? setFocusPrev() : null,
            onNavigateToNextRequest:
                () => setFocusNext ? setFocusNext() : null,
        };
    }
}

function WinPage() {
    const [state, setState] = useState(AllDigitInputsStateF.default());
    const inputFocus = {
        s1: useFocus(),
        s2: useFocus(),
        s3: useFocus(),
        s4: useFocus(),
    };


    const inputPropsFor = (
        getter: AllDigitInputsStateGetter,
        setter: AllDigitInputsStateSetter,
        focusRef: FocusRef,
        setFocusPrev: SetFocus | null,
        setFocusNext: SetFocus | null,
    ): ThreeDigitsInputProps => {
        return AllDigitInputsStateF.getPropsFor(
            state,
            getter,
            (event) => {
                const newState = AllDigitInputsStateF.updateText(
                    state,
                    setter,
                    event.target.value,
                );
                setState(newState);
            },
            focusRef,
            setFocusPrev,
            setFocusNext,
        )
    };

    const inputProps = {
        s1: inputPropsFor(
            (s) => s.s1,
            AllDigitInputsStateF.withS1,
            inputFocus.s1.ref,
            null,
            inputFocus.s2.set,
        ),
        s2: inputPropsFor(
            (s) => s.s2,
            AllDigitInputsStateF.withS2,
            inputFocus.s2.ref,
            inputFocus.s1.set,
            inputFocus.s3.set,
        ),
        s3: inputPropsFor(
            (s) => s.s3,
            AllDigitInputsStateF.withS3,
            inputFocus.s3.ref,
            inputFocus.s2.set,
            inputFocus.s4.set,
        ),
        s4: inputPropsFor(
            (s) => s.s4,
            AllDigitInputsStateF.withS4,
            inputFocus.s4.ref,
            inputFocus.s3.set,
            null,
        ),
    }

    const currentInput = useRef(0);
    const focusNext = () => {
        var s = inputFocus.s1;
        var n = 0;
        if (currentInput.current === 0) {
            s = inputFocus.s2;
            n = 1;
        } else if (currentInput.current === 1) {
            s = inputFocus.s3;
            n = 2;
        } else if (currentInput.current === 2) {
            s = inputFocus.s4;
            n = 3;
        }
        currentInput.current = n;
        s.set();
        setTimeout(focusNext, 1000);
    };
    // setTimeout(focusNext, 1000);

    return (<>
        <div className="d-flex flex-column container py-2 my-3 WinPage-container align-content-center justify-content-center">
            <div className="row gy-3">
                <div className="col-12">
                    <img className="WinPage-token-image" src={tokenPicture} />
                </div>
                <div className="col-12">
                    <div className="row align-items-center justify-content-center gx-0">
                        <ThreeDigitsInput
                            {...inputProps.s1} />
                        <ThreeDigitsSeparator />
                        <ThreeDigitsInput {...inputProps.s2} />
                        <ThreeDigitsSeparator />
                        <ThreeDigitsInput {...inputProps.s3} />
                        <ThreeDigitsSeparator />
                        <ThreeDigitsInput  {...inputProps.s4} />
                    </div>
                </div>
                <div className="col-12">
                    <span>Enter your secret code</span>
                </div>
            </div>
        </div>
    </>);
}

export default WinPage;