import './FaqPage.css';

import { useState, useEffect } from 'react';
import Markdown from 'react-markdown';

import faqMarkdown from './FaqPage/faq.md';

import Footer from '../components/Footer';

function FaqPage() {
    const [faq, setFaq] = useState("");

    useEffect(() => {
        fetch(faqMarkdown).then((response) => response.text()).then((text) => {
            setFaq(text);
        })
    });

    return (<>
        <div className="content Faq-content px-2">
            <Markdown>{faq}</Markdown>
        </div>
        <Footer />
    </>)
}

export default FaqPage;