import React, { useState } from 'react';

import {
    CreateAccountContentCard,
    CreateAccountButtonSubmit,
    CreateAccountDivider,
    CreateAccountHaveAnAccountLogin,
} from './CreateAccountWidgets';

import { PublicService, AuthCookieService, AuthenticatedService } from '../../API';
import { impossible } from '../../utils/impossible';

import './CreateAccountPage.css';
import { Redirect, RedirectAfterAuth } from '../../Redirect';
import Routing from '../../Routing';


function validateName(s: string): boolean {
    return s.length >= 1;
}

function validateEmail(s: string): boolean {
    return String(s)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
}

function validatePassword(s: string): boolean {
    return s.length >= 8;
}

function CreateAccountWithEmailPage() {
    const [helpState, setHelpState] = useState({ nameHelp: false, emailHelp: false, passwordHelp: false });
    const [canSubmit, setCanSubmit] = useState(true);
    const [remoteError, setRemoteError] = useState<string | null>(null);

    const [formName, setFormName] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");

    // TODO: https://legacy.reactjs.org/docs/forms.html#handling-multiple-inputs
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setCanSubmit(false);
        var shouldReenable = true;
        try {
            var newHelpState = { nameHelp: false, emailHelp: false, passwordHelp: false };
            var isValid = true;
            if (!validateName(formName)) {
                newHelpState.nameHelp = true;
                isValid = false;
            }
            if (!validateEmail(formEmail)) {
                newHelpState.emailHelp = true;
                isValid = false;
            }
            if (!validatePassword(formPassword)) {
                newHelpState.passwordHelp = true;
                isValid = false;
            }
            setHelpState(newHelpState);
            if (!isValid) {
                return;
            }

            const result = await PublicService().createAccount({
                name: formName,
                email: formEmail,
                password: formPassword,
            });
            var jwt = null;
            switch (result.result.kind) {
                case "error":
                    setRemoteError(result.result.error);
                    return;
                case "jwt":
                    jwt = result.result.jwt;
                    shouldReenable = false;
                    break;
                default:
                    impossible(result.result);
            }
            await AuthCookieService().authCookie({
                jwt: jwt,
            });
            setRemoteError(null);
            RedirectAfterAuth();
        } finally {
            if (shouldReenable) {
                setCanSubmit(true);
            }
        }
    };


    return (<form method="post" onSubmit={handleSubmit}>
        <CreateAccountContentCard title="Create Account">
            <>
                <label htmlFor="create-account-name" className="form-label">Name</label>
                <input
                    name="name"
                    type="text"
                    id="create-account-name"
                    value={formName}
                    onChange={(e) => setFormName(e.target.value)}
                    className="form-control" />
                {!helpState.nameHelp ? <></> :
                    <small className="form-text text-muted">
                        You must enter a name
                    </small>}
            </>
            <>
                <label htmlFor="create-account-email" className="form-label">Email</label>
                <input
                    name="email"
                    type="email"
                    id="create-account-email"
                    value={formEmail}
                    onChange={(e) => setFormEmail(e.target.value)}
                    className="form-control" />
                {!helpState.emailHelp ? <></> :
                    <small className="form-text text-muted">
                        You must enter a valid email address
                    </small>}
            </>
            <>
                <label htmlFor="create-account-password" className="form-label">Password</label>
                <input
                    name="password"
                    type="password"
                    id="create-account-password"
                    className="form-control"
                    value={formPassword}
                    onChange={(e) => setFormPassword(e.target.value)} />
                {!helpState.passwordHelp ? <></> :
                    <small className="form-text text-muted">
                        Passwords must be at least 8 characters
                    </small>}
            </>
            {remoteError === null ? <></> :
                <small className="form-text text-danger">
                    {remoteError}
                </small>}
            <CreateAccountButtonSubmit text="Create Account" disabled={!canSubmit} />
            <CreateAccountHaveAnAccountLogin />
        </CreateAccountContentCard>
    </form>);
}

export default CreateAccountWithEmailPage;