import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.min.css';

import App from './App';
import AboutPage from './pages/AboutPage';
import ContactUsPage from './pages/ContactUsPage';
import ExplorePage from './pages/ExplorePlage';
import FaqPage from './pages/FaqPage';
import Routing from './Routing';
import TermsOfUsePage from './pages/TermsOfUsePage';
import WinPage from './pages/WinPage';

import CreateAccountPage from './pages/Account/CreateAccountPage';
import CreateAccountWithEmailPage from './pages/Account/CreateAccountWihEmailPage';
import LoginPage from './pages/Account/LoginPage';

import reportWebVitals from './reportWebVitals';
import { NavbarPage } from './components/Navbar';
import { AccountInfoPage } from './pages/Account/AccountInfoPage';
import { LogoutPage } from './pages/Account/LogoutPage';
import { MyTokensPage } from './pages/Console/MyTokensPage';

const router = createBrowserRouter([
  {
    path: Routing.ABOUT_PAGE.url,
    element: (<App activeNavbarPage={NavbarPage.ABOUT}><AboutPage /></App>),
  },
  {
    path: Routing.EXPLORE_PAGE.url,
    element: <App fillHeight={true} activeNavbarPage={NavbarPage.EXPLORE}><ExplorePage /></App>,
  },
  {
    path: Routing.WIN_PAGE.url,
    element: <App fillHeight={true} activeNavbarPage={NavbarPage.WIN}><WinPage /></App>,
  },
  {
    path: Routing.CONTACT_US_PAGE.url,
    element: <App><ContactUsPage /></App>
  },
  {
    path: Routing.TERMS_OF_USE_PAGE.url,
    element: <App><TermsOfUsePage /></App>
  },
  {
    path: Routing.FAQ_PAGE.url,
    element: <App><FaqPage /></App>,
  },
  {
    path: Routing.MY_TOKENS_PAGE.url,
    element: <App fillHeight={true}><MyTokensPage /></App>,
  },
  {
    path: Routing.ACCOUNT_INFO_PAGE.url,
    element: <App><AccountInfoPage /></App>,
  },
  {
    path: Routing.CREATE_ACCOUNT_PAGE.url,
    element: <App fillHeight={true}><CreateAccountWithEmailPage /></App>
  },
  {
    path: Routing.LOGIN_PAGE.url,
    element: <App fillHeight={true}><LoginPage /></App>
  },
  {
    path: Routing.LOGOUT_PAGE.url,
    element: <LogoutPage />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
