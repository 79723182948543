

import './AboutPage.css';
import journeyStartIcon from './AboutPage/journey_start.png';
import journeyFoundIcon from './AboutPage/journey_found.png';
import journeyEndIcon from './AboutPage/journey_end.png';
import bannerPicture from './AboutPage/banner_picture_low.jpg';
import treasureIcon from './AboutPage/treasure.png';
import membersIcon from './AboutPage/members.png';
import tokenIcon from './AboutPage/token.jpeg';

import { ReactNode, useEffect } from 'react';

import Footer from '../components/Footer';
import Routing from '../Routing';

interface JourneyComponentProps {
  icon: string;
  title: string;
  detail: ReactNode;
}

function JourneyComponent(props: JourneyComponentProps) {
  return (<div className="col-md-4">
    <div className="row gy-3 align-items-center">
      <div className="col-4 col-md-12">
        <div className="About-journey-icon mx-auto">
          <img src={props.icon} />
        </div>
      </div>
      <div className="col-8 col-md-12 text-start text-md-center">
        <div className="row">
          <div className="col-12">
            <span className="m-0"><b>{props.title}</b></span>
          </div>
          <div className="col-12">
            <span>{props.detail}</span>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

interface LearnMoreComponentProps {
  icon: string;
  title: string;
  detail: ReactNode;
}

function LearnMoreComponent(props: LearnMoreComponentProps) {
  return (<div className="col-md-6 About-learn-more-col mx-auto">
    <div className="row gy-3">
      <div className="col-12 text-start">
        <span className="m-0"><b>{props.title}</b></span>
      </div>
      <div className="col-12">
        <div className="About-learn-more-icon">
          <img src={props.icon} />
        </div>
      </div>
      <div className="col-12 text-start">
        <span>{props.detail}</span>
      </div>
    </div>
  </div>
  );
}

function AboutPage() {
  return (
    <>
      <div className="About-container container-fluid p-0">
        <div className="row m-0">
          <div className="col p-0">
            <div
              className="About-banner-picture d-flex align-items-center justify-content-center"
              style={{ backgroundImage: `url(${bannerPicture})` }}>
              <h1
                className="About-banner-text">Join our real-life Treasure Hunt.</h1>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0 py-4 About-dark-background">
          <div className="container-sm About-journey-container">
            <div className="row m-4">
              <h2 className="align-middle m-0">3 STEPS TO FINDING YOUR TREASURE</h2>
            </div>
            <div className="row gy-5">
              <JourneyComponent
                icon={journeyStartIcon}
                title="Step 1: Go Discover"
                detail={(<>
                  Choose a bounty and go hunting! Explore <a href={Routing.EXPLORE_PAGE.url}>our interactive map</a> to find a bounty nearby.
                </>)} />
              <JourneyComponent
                icon={journeyFoundIcon}
                title="Step 2: Locate Token"
                detail="Find the token with the secret code. Write the code down and leave the token behind for your fellow hunters." />
              <JourneyComponent
                icon={journeyEndIcon}
                title="Step 3: Play & Win"
                detail={(<>
                  <a href={Routing.WIN_PAGE.url}>Record the secret code</a> and discover how much GeoCoins you won! Spend your GeoCoins in our Shop or on Amazon Gift Cards.
                </>)} />
            </div>
          </div>
        </div>
        <div className="container-fluid p-0 py-4">
          <div className="container-sm About-learn-more-container">
            <div className="row gy-4">
              <LearnMoreComponent
                icon={treasureIcon}
                title="Real treasures?"
                detail={(<>
                  Each treasure will win you a variable amount of GeoCoins, based on chance!
                  Spend your coins in our Shop or on Amazon Gift Cards! <a href={Routing.FAQ_PAGE.are_treasures_real.url()}>Learn more</a>
                </>)} />
              <LearnMoreComponent
                icon={membersIcon}
                title="Who funds treasures?"
                detail={(<>
                  We use 80% of our membership fees to fund our treasure hunt. Pay to play! Will you hit it big? <a href={Routing.FAQ_PAGE.who_funds_treasures.url()}>Learn more</a>
                </>)} />
              <LearnMoreComponent
                icon={tokenIcon}
                title="How do tokens work?"
                detail={(<>
                  A physical token serves as proof that you completed the treasure hunt. Use the secret code to claim your reward. <a href={Routing.FAQ_PAGE.how_do_tokens_work.url()}>Learn more</a>
                </>)} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutPage;
