import React from 'react';
import logo from '../logo.svg';
import './Navbar.css';

import Routing from '../Routing';
import { AccountInfoResult } from '../../../common/ts/wwwapi';

enum NavbarPage {
    ABOUT,
    EXPLORE,
    WIN,
}

interface NavbarProps {
    activePage: NavbarPage | null,
    accountInfo: AccountInfoResult | null | "downloading"
}

function Navbar(props: NavbarProps) {
    function is_active(p: NavbarPage): string {
        return p === props.activePage ? "active" : "";
    }

    // Taken from https://getbootstrap.com/docs/5.0/components/navbar/
    //
    // Also has some options for dropdowns and stuff
    const header = (<header className="border-bottom">
        <nav className="Navbar-navbar navbar navbar-expand-md navbar-light bg-white">
            <div className="container-fluid">
                <a className="navbar-brand" href={Routing.ABOUT_PAGE.url}>GEO BOUNTY HUNT</a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <li className="nav-item">
                            <a className={`nav-link d-flex align-items-center ${is_active(NavbarPage.ABOUT)}`} href={Routing.ABOUT_PAGE.url}>
                                <i className="bi bi-patch-question pe-2 d-inline-flex"></i>
                                ABOUT
                            </a>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <span className="nav-link">&middot;</span>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link d-flex align-items-center ${is_active(NavbarPage.EXPLORE)}`} href={Routing.EXPLORE_PAGE.url}>
                                <i className="bi bi-globe-europe-africa pe-2 d-inline-flex"></i>
                                EXPLORE
                            </a>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <span className="nav-link">&middot;</span>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link d-flex align-items-center ${is_active(NavbarPage.WIN)}`} href={Routing.WIN_PAGE.url}>
                                <i className="bi bi-trophy pe-2 d-inline-flex"></i>
                                WIN
                            </a>
                        </li>
                    </ul>
                    {props.accountInfo === "downloading" ?
                        null :
                        props.accountInfo !== null ?
                            (<ul className="d-flex navbar-nav">
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        Hi {props.accountInfo.name.split(' ').at(0)}!
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                        <li><a className="dropdown-item" href={Routing.MY_TOKENS_PAGE.url}>My Tokens</a></li>
                                        <li><a className="dropdown-item" href="#">My Orders</a></li>
                                        <li><a className="dropdown-item" href={Routing.ACCOUNT_INFO_PAGE.url}>My Account</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" href={Routing.LOGOUT_PAGE.url}>Log out</a></li>
                                    </ul>
                                </li>
                            </ul>) : (
                                <ul className="d-flex navbar-nav">
                                    <li className="nav-item mb-2 mb-md-0 ms-md-2">
                                        <a className="btn btn-outline-success w-100 w-md-auto" href={Routing.LOGIN_PAGE.url} role="button">Log In</a>
                                    </li>
                                    <li className="nav-item mb-2 mb-md-0 ms-md-2">
                                        <a className="btn btn-success w-100 w-md-auto" href={Routing.CREATE_ACCOUNT_PAGE.url} role="button">Sign Up</a>
                                    </li>
                                </ul>
                            )}
                </div>
            </div>
        </nav>
    </header>);
    return header;
}

export { NavbarPage, Navbar };
