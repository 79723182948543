import React, { useRef } from 'react';

type FocusRef = React.MutableRefObject<HTMLInputElement | null>;
type SetFocus = () => void;

/**
 * Dynamically focus on input.
 * 
 * Usage:
 * 
 * ```
 * const focus = useFocus();
 * const elem = (<input ref={focus.ref} />);
 * focus.set();
 * ```
 */
function useFocus(): {
    ref: FocusRef,
    set: SetFocus,
} {
    const htmlElRef = useRef<HTMLInputElement | null>(null);
    const setFocus = () => {
        if (htmlElRef.current) {
            htmlElRef.current.focus();
        }
    }

    return {
        ref: htmlElRef,
        set: setFocus,
    };
}

export { type FocusRef, type SetFocus, useFocus };