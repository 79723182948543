import { ConsoleSidebar } from './ConsoleSidebar';

export function MyTokensPage() {
    return (<div className="container-fluid h-100">
        <div className="row h-100">
            <ConsoleSidebar />
            <main className="Console-main col-12 col-md-9 col-lg-10 p-3 h-100">
                <div className="card">
                    <div className="card-header bg-success text-white">
                        Tokens waiting for you
                    </div>
                    <div className="card-body">
                        <table className="table align-middle">
                            <colgroup>
                                <col className="Console-icon-column" />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="Console-icon-cell">
                                        <i className="bi bi-person"></i>
                                    </td>
                                    <td>City Adventure</td>
                                    <td>Discovered on 23 June 2024</td>
                                    <td>Play!</td>
                                </tr>
                                <tr>
                                    <td className="Console-icon-cell">
                                        <i className="bi bi-person"></i>
                                    </td>
                                    <td>City Adventure</td>
                                    <td>Discovered on 23 June 2024</td>
                                    <td>Play!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header">
                        Historical tokens
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Discovered on 23 June 2024</td>
                                    <td>City Adventure</td>
                                    <td>Play!</td>
                                </tr>
                                <tr>
                                    <td>Discovered on 23 June 2024</td>
                                    <td>City Adventure</td>
                                    <td>Play!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    </div>);
}
