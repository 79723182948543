import { useState } from "react";
import { AuthenticatedService } from "../../API"
import { useAsyncEfect } from "../../utils/ReactUtils";
import { AccountInfoResult } from "../../../../common/ts/wwwapi";

export function AccountInfoPage() {
    const [user, setUser] = useState<AccountInfoResult | null>(null);
    useAsyncEfect(async () => {
        const u = (await AuthenticatedService().accountInfo()).authorize();
        setUser(u);
    }, [])
    return (
        <span>Hello {user?.name}!</span>
    )
}