import './TermsOfUsePage.css';

import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';
import { ExtraProps } from 'hast-util-to-jsx-runtime';

import termsOfuseMarkdown from './TermsOfUsePage/terms_of_use.md';
import privacyPolicyMarkdown from './TermsOfUsePage/privacy_policy.md';

import Footer from '../components/Footer';

type HeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
    & ExtraProps & PropsWithChildren;

function getHeaderId(props: HeaderProps): string {
    const children = Array.isArray(props.children)
        ? props.children
        : [props.children];
    const heading = children
        .flatMap((element) =>
            typeof element === 'string'
                ? element
                : element?.type !== undefined &&
                    typeof element.props.children === 'string'
                    ? element.props.children
                    : []
        )
        .join('');

    let str = heading;
    str = str?.replace(/^\s+|\s+$/g, '');
    str = str?.toLowerCase();
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc------';

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
        ?.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
}


function headerTag(props: HeaderProps) {
    const tagName = props.node?.tagName;
    if (!tagName) {
        return null;
    }

    let id = undefined;
    if (tagName === "h1") {
        id = getHeaderId(props);
    }
    return React.createElement(tagName, { id: id }, props.children);
}

function TermsOfUsePage() {
    const location = useLocation();
    const lastHash = useRef('');

    const [termsOfUse, setTermsOfUse] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");

    useEffect(() => {
        console.log("useEffect");
        fetch(termsOfuseMarkdown).then((response) => response.text()).then((text) => {
            setTermsOfUse(text);
        });
        fetch(privacyPolicyMarkdown).then((response) => response.text()).then((text) => {
            setPrivacyPolicy(text);
        });

        if (location.hash && location.hash != lastHash.current) {
            const hash = location.hash.slice(1);
            const element = document.getElementById(hash);
            if (element) {
                lastHash.current = hash;
                setTimeout(() => {
                    document.getElementById(lastHash.current)
                    ?.scrollIntoView({behavior: 'smooth',  block: 'start' });
                }, 100);
            }
        }
    });

    const components = {
        h1: headerTag,
        h2: headerTag,
        h3: headerTag,
        h4: headerTag,
        h5: headerTag,
        h6: headerTag,
    };

    return (<>
        <div className="content TermsOfUse-content px-2">
            <Markdown components={components}>{termsOfUse}</Markdown>
            <hr />
            <Markdown components={components}>{privacyPolicy}</Markdown>
        </div>
        <Footer />
    </>);
}

export default TermsOfUsePage;