import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import Routing from './Routing';

const AUTH_THEN_REDIRECT_COOKIE_KEY = 'AuthThenRedirect';

export function AuthThenRedirect(): void {
    const currentURL = window.location.href;
    setCookie(AUTH_THEN_REDIRECT_COOKIE_KEY, currentURL);

    window.location.href = Routing.LOGIN_PAGE.url;
}

export function RedirectAfterAuth(): void {
    const nextURL = getCookie(AUTH_THEN_REDIRECT_COOKIE_KEY);
    if (nextURL) {
        window.location.href = nextURL;
        removeCookie(AUTH_THEN_REDIRECT_COOKIE_KEY);
    } else {
        window.location.href = Routing.ACCOUNT_INFO_PAGE.url;
    }
}

export function Redirect(url: string): void {
    window.location.href = url;
}