import './ContactUsPage.css';

import Footer from '../components/Footer';

function ContactUsPage() {
    return (<>
        <div className="ContactUsPage container my-4 App-limit-width text-start">
            <div className="card">
                <div className="card-header">
                    <span>Any questions? Why not pop us a message!</span>
                </div>
                <div className="card-body">
                    <p>
                        Any questions? Suggestions? Complaints? Or something you'd like to share? We'd love to hear it!
                    </p>
                    <p>
                        We'll send you a confirmation email when we received your message. It might take us a day or two to get back to you.
                    </p>

                    <hr />
                    <div className="row gy-3">
                        <div className="col-sm-6">
                            <label className="form-label">Name</label>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" />
                        </div>
                        <div className="col-12">
                            <label className="form-label">Your question or message</label>
                            <textarea className="form-control" rows={3}></textarea>
                        </div>
                        <div className="col-12">
                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-success">Send it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>);
}

export default ContactUsPage;
