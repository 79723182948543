import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.js';

import './ExplorePage.css';

import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import { Marker, Popup } from 'react-leaflet';
import { useLeafletContext } from '@react-leaflet/core'
import L, { icon } from 'leaflet';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import { TokenResult } from '../../../common/ts/wwwapi';
import { PublicService } from '../API';
import { useEffect, useState } from 'react';
import { useAsyncEfect } from '../utils/ReactUtils';

// For documentation on the map
//
// See https://react-leaflet.js.org/docs/example-events/

function markerForTokenSummary(t: TokenResult, i: number): JSX.Element {
    return (
        <Marker
            key={i}
            icon={icon({ iconUrl: markerIcon })}
            position={[t.lat, t.long]}>
            <Popup>
                {t.name}
                <br /> Easily customizable.
            </Popup>
        </Marker>
    );
}

function ExploreMapComponent() {
    const map = useMapEvents({
        // The `moveend` event should fire on zoom changes, moves due to drag,
        // and window resizes, so should capture anything.
        moveend: (event) => {
            console.log("MAP: moveend:", event);
        },
        zoomend: (event) => {
            console.log("MAP: zoomend:", event);
        },
    });
    return null;
}

const DEFAULT_CENTRE = {
    // Big Ben
    lat: 51.5007,
    long: -0.1246,
};

function TokensElement() {
    const [allTokens, setAllTokens] = useState<TokenResult[]>([]);
    const [center, setCenter] = useState(DEFAULT_CENTRE);
    const { map } = useLeafletContext();

    useAsyncEfect(async () => {
        const tokens = await PublicService().queryAllTokens();
        setAllTokens(tokens);

        const firstToken = tokens.at(0);
        const center = firstToken ? {
            lat: firstToken.lat,
            long: firstToken.long,
        } : DEFAULT_CENTRE;
        setCenter(center);
    }, []);

    useEffect(() => {
        map.panTo(
            new L.LatLng(center.lat, center.long),
        );
    }, [center]);

    const tokenMarkers = allTokens.map(markerForTokenSummary);

    return (<>{tokenMarkers}</>);
}

function ExplorePage() {
    const mapStyle = {
        height: "100%",
    };

    const center = DEFAULT_CENTRE;

    return (<MapContainer
        style={mapStyle}
        attributionControl={false}
        center={[center.lat, center.long]}
        zoom={13}
        scrollWheelZoom={true}>
        <ExploreMapComponent />
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <TokensElement />
    </MapContainer>
    )
}

export default ExplorePage;
