import React from 'react';
import './Console.css';

function ConsoleSidebarHeading(props: { isFirst: boolean } & React.PropsWithChildren) {
    const mt = props.isFirst ? "" : "mt-4";
    return (<h6 className={`Console-sidebar-heading px-3 mb-1 ${mt} text-muted`}>
        {props.children}
    </h6>);
}

function ConsoleSidebarNavList(props: React.PropsWithChildren) {
    return (<ul className="nav flex-column">
        {props.children}
    </ul>);
}

function ConsoleSidebarNavListItem(props: { iconName: string, title: string }) {
    return (<li className="nav-item">
        <a className="nav-link d-flex align-items-center" href="#">
            <i className={`bi ${props.iconName} pe-2`}></i>
            {props.title}
        </a>
    </li>);
}

export function ConsoleSidebar() {
    return (<nav id="sidebarMenu" className="d-none d-md-block col-md-3 col-lg-2 bg-light h-100 Console-sidebar">
        <ConsoleSidebarHeading isFirst={true}>
            <span>You</span>
        </ConsoleSidebarHeading>
        <ConsoleSidebarNavList>
            <ConsoleSidebarNavListItem
                iconName='bi-ticket-perforated'
                title='My Tokens' />
            <ConsoleSidebarNavListItem
                iconName='bi-cart-check'
                title='My Orders' />
            <ConsoleSidebarNavListItem
                iconName='bi-person'
                title='My Account' />
        </ConsoleSidebarNavList>
        <ConsoleSidebarHeading isFirst={false}>
            <span>Get going</span>
        </ConsoleSidebarHeading>
        <ConsoleSidebarNavList>
            <ConsoleSidebarNavListItem
                iconName='bi-globe-europe-africa'
                title='Explore' />
            <ConsoleSidebarNavListItem
                iconName='bi-trophy'
                title='Register token' />
            <ConsoleSidebarNavListItem
                iconName='bi-shop'
                title='Shop' />
        </ConsoleSidebarNavList>
    </nav>);
}
