import './Footer.css';

import { PropsWithChildren } from 'react';

import Routing from '../Routing';

interface FooterItemProps {
    name: string;
    url: string;
}

function FooterItem(props: FooterItemProps) {
    return (
        <div className="py-1">
            <span><a href={props.url}>{props.name}</a></span>
        </div>
    );
}

interface FooterColumnProps extends PropsWithChildren {
    isLast: boolean;
    title: string;
}

function FooterColumn(props: FooterColumnProps) {
    return (
        <div className="col-md-auto col-12">
            <div className="text-md-start text-center py-2">
                <div className="py-2"><span><b>{props.title}</b></span></div>
                {props.children}
                {props.isLast ? null :
                    <div className="border-bottom py-2 d-md-none mx-auto" style={{ width: "50%" }}></div>}
            </div>
        </div>
    );
}

function Footer() {
    return (<>
        <div className="container-fluid Footer-background-container">
            <div className="container-sm Footer-content-container">
                <div className="row py-2 justify-content-between">
                    <FooterColumn title="About Us" isLast={false}>
                        <FooterItem name="Get started" url={Routing.ABOUT_PAGE.url}/>
                        <FooterItem name="Browse our treasures" url={Routing.EXPLORE_PAGE.url} />
                        <FooterItem name="Enter a secret code" url={Routing.WIN_PAGE.url} />
                        <FooterItem name="Learn about GeoCoins" url={Routing.FAQ_PAGE.how_do_geocoins_work.url()} />
                    </FooterColumn>
                    <FooterColumn title="Community Rules" isLast={false}>
                        <FooterItem name="How we fund our treasures" url={Routing.FAQ_PAGE.who_funds_treasures.url()} />
                        <FooterItem name="How we determine our winnings" url={Routing.FAQ_PAGE.how_winnings_are_determined.url()} />
                        <FooterItem name="Leaving tokens behind" url={Routing.FAQ_PAGE.leaving_tokens_behind.url()} />
                        <FooterItem name="Frequently asked questions" url={Routing.FAQ_PAGE.url} />
                    </FooterColumn>
                    <FooterColumn title="Help and Legal" isLast={true}>
                        <FooterItem name="FAQ" url={Routing.FAQ_PAGE.url} />
                        <FooterItem name="Terms of Use" url={Routing.TERMS_OF_USE_PAGE.url} />
                        <FooterItem name="Contact Us" url={Routing.CONTACT_US_PAGE.url} />
                    </FooterColumn>
                </div>
            </div>
        </div>
        <div className="container-fluid Footer-copyright-background d-flex d-row flex-wrap p-1 justify-content-center">
            <div className="px-2"><span>&copy; Geo Bounty Hunt, Ltd. All rights reserved.</span></div>
            <div className="d-flex d-row flex-wrap">
                <div className="px-2"><span><a href={Routing.TERMS_OF_USE_PAGE.terms_of_use_heading.url()}>Terms of Use</a></span></div>
                <div className="px-2"><span><a href={Routing.TERMS_OF_USE_PAGE.privacy_policy_hading.url()}>Privacy Policy</a></span></div>
            </div>
        </div>
    </>);
}

export default Footer;